<template>
  <y_layout>
    <router-view/>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";

export default {
  name: "index",
  components: {y_layout},
}
</script>

<style scoped>

</style>